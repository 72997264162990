import React from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { Box } from "atomic-layout";
import { Page, Title, Button } from "../components";
import { AnimatePresence, motion } from "framer-motion";

const Container = styled(Box)`
  width: 90%;
  max-width: 1220px;
  margin: auto;
  position: relative;
  padding-top: 130px;

  ${media.lessThan("medium")`
    padding-top: 60px;

    &>div:first-of-type {
      width: 100%;
      margin: 0;
    }
  `}

  :after {
    content: "";

    width: 130%;
    height: calc(160% + 130px);
    position: absolute;
    background: #27494a;
    opacity: 0.1;
    left: 22%;
    top: -130px;
    pointer-events: none;

    ${media.lessThan("medium")`
      display: none;
    `}
  }

  ${Title} {
    position: relative;
    z-index: 2;

    ${media.lessThan("medium")`
      font-size: 50px;
    `}
  }
`;

const Form = styled(Box)`
  margin-top: 250px;
  max-width: 665px;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  position: relative;
  z-index: 2;

  ${media.lessThan("medium")`
    margin-top: 30px;
  `}

  button {
    margin-top: 10px;
  }
`;
const Fieldset = styled(Box)`
  padding: 0;
  margin: 0;
  border: 0;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  border: 0;
  background: transparent;
  border-bottom: 1px solid #6c6c6c;
  padding: 15px;
  font-family: "Raleway";
  color: #6c6c6c;
  font-size: 16px;
  margin-bottom: 30px;
  transition: all 200ms ease;

  &:focus,
  &:active {
    outline: none;
    border-color: #5273b0;
  }
`;
const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  border: 0;
  background: transparent;
  border-bottom: 1px solid #6c6c6c;
  padding: 15px;
  font-family: "Raleway";
  color: #6c6c6c;
  font-size: 16px;
  margin-bottom: 30px;
  resize: none;
  transition: all 200ms ease;

  &:focus,
  &:active {
    outline: none;
    border-color: #5273b0;
  }
`;

const Map = styled(Box)`
  position: relative;
  z-index: 2;
  margin: auto;
  margin-top: 60px;
`;

const Overlay = styled(Box)`
margin-top: 250px;
max-width: 665px;
margin-left: auto;
display: flex;
flex-flow: column wrap;
justify-content: center;
align-items: center;
position: relative;
z-index: 2;
min-height: 497px;

${media.lessThan("medium")`
  margin-top: 30px;
`}

  p {
    margin-bottom: 32px;
  }
`;

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Contato = () => {
  const [state, setState] = React.useState({});
  const [sent, setSent] = React.useState(false);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => {
        setSent(true)
        setState({})
      })
      .catch((error) => alert(error));
  };

  return (
    <Page title="Contato" backgroundColor="white">
      <Container flex flexWrap="wrap" flexDirection="row">
        <Title color="#27494A">Contato</Title>
        <Box width="50%" marginLeft="auto">
          <AnimatePresence>
            {sent ? (
              <Overlay as={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ type: 'spring', bounce: 0.1 }}>
                <h3>Formulário enviado com sucesso.</h3>
                <p>Em breve entraremos em contato</p>
                <Button onClick={() => setSent(false)}>Enviar nova mensagem</Button>
              </Overlay>
            ) : (
              <Form
                as={motion.form}
                name="contact"
                method="post"
                action="/thanks/"
                initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ type: 'spring', bounce: 0.1 }}
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <input type="hidden" name="form-name" value="contact" />
                <Fieldset as="fieldset">
                  <Input name="name" type="text" placeholder="Nome" required onChange={handleChange} />
                </Fieldset>

                <Fieldset as="fieldset" width="45%">
                  <Input
                    name="email"
                    type="email"
                    placeholder="E-mail"
                    required
                    onChange={handleChange}
                  />
                </Fieldset>

                <Fieldset as="fieldset" width="45%">
                  <Input
                    name="email"
                    type="tel"
                    placeholder="Telefone"
                    required
                    onChange={handleChange}
                  />
                </Fieldset>

                <Fieldset as="fieldset">
                  <Input
                    name="subject"
                    type="text"
                    placeholder="Assunto"
                    required
                    onChange={handleChange}
                  />
                </Fieldset>

                <Fieldset as="fieldset">
                  <Textarea name="message" placeholder="Mensagem" required onChange={handleChange} />
                </Fieldset>

                <Box flex width="100%" justifyContent="center" marginBottom={60}>
                  <Button color="#5273B0" type="submit">
                    Enviar
                  </Button>
                </Box>
              </Form>
            )}
          </AnimatePresence>
          
        </Box>
      </Container>
      <Map width="90%" maxWidth={1670} height={970} style={{ background: "grey" }}>
        <iframe title="Gmaps" width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Rua%20At%C3%ADlio%20Andreazza%203112,%20Sagrada%20Fam%C3%ADlia%20-%20Caxias%20do%20Sul/RS%20Caxias%20do%20Sul+(Origgem)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
      </Map>
    </Page>
  );
};

export default Contato;
